import axios from 'axios'
import qs from 'qs'
import { Platform } from 'quasar'
import { cacheAdapterEnhancer } from 'axios-extensions'

import { isDev } from '../constants'
import TempCacheStorage from 'src/utils/temp-cache-storage.js'
import PersistedCacheStorage from 'src/utils/persisted-cache-storage.js'
import { isEqualObject, reloadPage, normalizeUrlToPath } from 'src/utils'

const persistedCacheStorage = new PersistedCacheStorage()
export const tempCacheStorage = new TempCacheStorage()

const { API_ROOT_URI } = process.env,
  { IDENTITY_API_URL } = process.env,
  { Tenant } = process.env,
  { StoreFrontID } = process.env,
  reqHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // "Content-Type": "multipart/form-data",
    'Cache-Control': 'no-cache',
  }

if (Platform?.is?.cordova || isDev) {
  reqHeader['Tenant'] = Tenant
  reqHeader['StoreFrontID'] = StoreFrontID
}

let objRequests = {}
let routeName = null
let reqInterceptor = null,
  resInterceptor = null,
  identityReqInterceptor = null,
  identityResInterceptor = null

const apiInstance = axios.create({
  baseURL: API_ROOT_URI,
  withCredentials: false, // This is the default
  headers: reqHeader,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
  }),
})

const identityAxiosInstance = axios.create({
  baseURL: IDENTITY_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const setAVSessionID = (sessionId) => {
  apiInstance.defaults.headers.common['AVSessionID'] = sessionId
}

export const removeAVSessionID = () => {
  if (apiInstance.defaults.headers.common?.AVSessionID)
    delete apiInstance.defaults.headers.common.AVSessionID
}

const setAxiosHeaders = (token, tokenType = 'Bearer') => {
  apiInstance.defaults.headers.common['Authorization'] = `${tokenType} ${token}`

  identityAxiosInstance.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${token}`
}

const clearRequest = (config) => {
  if (config && config.url && objRequests[config.url]) {
    let pageIndex = (config.params && config.params.page) || 0
    delete objRequests[config.url][pageIndex]
  }
}

export const setRouteName = (name) => {
  routeName = name
}

export const resetObjRequests = () => {
  for (let key in objRequests) {
    for (let page in objRequests[key]) {
      if (objRequests[key][page].cancel) objRequests[key][page].cancel()
    }
  }

  objRequests = Object.assign({})
}

const createAxiosCancelSource = () => axios.CancelToken.source()

const isCancelRequest = (error) => axios.isCancel(error)

export let handleTokenExpire

export default ({ store, Vue }) => {
  if (!!reqInterceptor || reqInterceptor === 0)
    apiInstance.interceptors.request.eject(reqInterceptor)

  if (!!resInterceptor || resInterceptor === 0)
    apiInstance.interceptors.response.eject(resInterceptor)

  if (!!identityReqInterceptor || identityReqInterceptor === 0)
    identityAxiosInstance.interceptors.request.eject(identityReqInterceptor)

  if (!!identityResInterceptor || identityResInterceptor === 0)
    identityAxiosInstance.interceptors.response.eject(identityResInterceptor)

  let refreshPromise = null

  const refreshClientCred = async (error, isIdentity = false) => {
    try {
      const config = error.config

      // if (
      //   error.config.headers['Authorization'] ==
      //   apiInstance.defaults.headers.common['Authorization']
      // ) {
      if (!refreshPromise) refreshPromise = ClientCredTokenAgent.getToken()

      let response = await refreshPromise

      refreshPromise = null
      setAxiosHeaders(response.data.access_token)
      config.headers[
        'Authorization'
      ] = `${response.data.token_type} ${response.data.access_token}`

      await store.commit('persisted/SET_CLIENT_CRED', {
        access_token: response.data.access_token,
        // token_type: response.data.token_type,
      })
      // }

      let instance = isIdentity ? identityAxiosInstance : apiInstance
      return instance(config)

      // return new Promise((resolve, reject) => {
      //   apiInstance
      //     .request(config)
      //     .then((response) => {
      //       resolve(response)
      //     })
      //     .catch((error) => {
      //       reject(error)
      //     })
      // })
    } catch (err) {
      console.log('unable to generate client cred token', err)
      refreshPromise = null
      return new Promise((_, reject) => {
        reject(error)
      })
    }
  }

  handleTokenExpire = (error, isIdentity = false) => {
    if (error?.config) {
      if (error.config._retry) return
      error.config._retry = true
    }

    if (store.getters['auth/isLoggedIn']) {
      return Vue.$oidc
        .getUser()
        .then(async (user) => {
          if (user?.access_token) {
            try {
              let config
              if (error?.config) config = error.config

              if (!refreshPromise) {
                refreshPromise = Vue.$oidc.signinSilent().finally(() => {
                  refreshPromise = null
                })
              }

              const refreshData = await refreshPromise

              if (refreshData?.access_token) {
                store.commit('auth/SET_USER', refreshData)

                setAxiosHeaders(refreshData.access_token)

                if (config) {
                  config.headers[
                    'Authorization'
                  ] = `${refreshData.token_type} ${refreshData.access_token}`

                  // store.dispatch('auth/mapUser')

                  let instance = isIdentity
                    ? identityAxiosInstance
                    : apiInstance

                  return instance(config)

                  // return new Promise((resolve, reject) => {
                  //   instance
                  //     .request(config)
                  //     .then((response) => {
                  //       resolve(response)
                  //     })
                  //     .catch((error) => {
                  //       reject(error)
                  //     })
                  // })
                }
                return true
              } else {
                await Vue.$oidc.removeUser()
                reloadPage()
              }
            } catch (error) {
              await Vue.$oidc.removeUser()
              reloadPage()
            }
          }
          // else {
          //   return refreshClientCred(error)
          // }
        })
        .catch((err) => {
          // return refreshClientCred(error)
        })
    } else return refreshClientCred(error, isIdentity)
  }

  identityReqInterceptor = identityAxiosInstance.interceptors.request.use(
    (config) => {
      let token
      if (store.getters['auth/isLoggedIn']) {
        token = store.getters['auth/accessToken']
      } else {
        token = store.getters['persisted/clientAccessToken']
      }
      if (token) config.headers['Authorization'] = `Bearer ${token}`

      return config
    }
  )

  reqInterceptor = apiInstance.interceptors.request.use(
    (config) => {
      // ToDo: Temp Code Start
      if (!config.url.startsWith('/')) config.url = `/${config.url}`

      if (!(config.url.startsWith('/v1') || config.url.startsWith('/v2'))) {
        config.url = `/v1${config.url}`
      }
      // Temp Code End

      let token
      if (store.getters['auth/isLoggedIn']) {
        token = store.getters['auth/accessToken']
      } else {
        token = store.getters['persisted/clientAccessToken']
      }
      if (token) config.headers['Authorization'] = `Bearer ${token}`

      if (process.env.SERVER) {
        // if (process.env.DEV) {
        //   config.headers['TenantUrl'] = `https://${process.env.Tenant_ACR}`
        // }
        config.headers['Origin'] = process.env.fullSsrUrl
        config.headers['Referer'] = `${process.env.fullSsrUrl}/`
      }

      if (config.params?.global) {
        delete config.params.global
        return config
      }

      if (!routeName || config.method.toLocaleLowerCase() !== 'get')
        return config

      let axiosSource = createAxiosCancelSource()
      config.cancelToken = axiosSource.token

      let pageIndex = (config.params && config.params.page) || 0,
        oldParams = null,
        requestUniqueKey =
          (config.params && config.params.requestUniqueKey) || ''

      let reqUrl = `${config.url}${
        requestUniqueKey ? `-${requestUniqueKey}` : ''
      }`

      if (objRequests[reqUrl]) {
        if (
          objRequests[reqUrl][pageIndex] &&
          objRequests[reqUrl][pageIndex].params
        ) {
          oldParams = objRequests[reqUrl][pageIndex].params
        } else {
          for (let page in objRequests[reqUrl]) {
            if (objRequests[reqUrl][page].params) {
              oldParams = objRequests[reqUrl][page].params
              break
            }
          }
        }
        if (oldParams !== null) {
          let blnIsSameRequest = isEqualObject(config.params || {}, oldParams)

          if (blnIsSameRequest) {
            if (
              objRequests[reqUrl][pageIndex] &&
              objRequests[reqUrl][pageIndex].cancel
            ) {
              objRequests[reqUrl][pageIndex].cancel()
            }
          } else {
            for (let page in objRequests[reqUrl]) {
              if (objRequests[reqUrl][page].cancel) {
                oldParams = objRequests[reqUrl][page].cancel()
                delete objRequests[reqUrl][page]
              }
            }
          }
        }
      }

      if (!objRequests[reqUrl]) {
        objRequests[reqUrl] = {}
      }

      objRequests[reqUrl][pageIndex] = {
        cancel: axiosSource.cancel,
        params: config.params || {},
      }

      return config
    },
    (error) => {}
  )

  resInterceptor = apiInstance.interceptors.response.use(
    (response) => {
      if (response && response.config) clearRequest(response.config)
      return response
    },
    (error) => {
      if (isCancelRequest(error)) {
        if (error.response && error.response.config)
          clearRequest(error.response.config)

        return null
      }

      if (401 === error?.response?.status) {
        return handleTokenExpire(error, false)
      } else {
        return Promise.reject(
          error.response ? error.response.data || false : false
        )
      }
    }
  )

  identityResInterceptor = identityAxiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (401 === error?.response?.status) {
        return handleTokenExpire(error, true)
      } else {
        return Promise.reject(
          error.response ? error.response.data || false : false
        )
      }
    }
  )

  store.$axiosInstance = axiosInstance
}

export const ClientCredTokenAgent = {
  getToken: () => {
    return axios.post(
      `${process.env.OIDC_AUTHORITY}/connect/token`,
      qs.stringify({
        client_id: process.env.CLIENT_CREDENTIALS_CLIENTID,
        client_secret: process.env.CLIENT_CREDENTIALS_SECRET,
        grant_type: process.env.CLIENT_CREDENTIALS_GRANT_TYPE,
        // atl: 1 * 60,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  },
}

const replaceUrl = (url, data = {}) => {
  let keys = []
  if (data instanceof FormData) {
    for (const key of data.keys()) {
      keys.push(key)
    }
  } else keys = Object.keys(data)

  let regex = new RegExp(':(' + keys.join('|') + ')', 'g')

  return url.toLowerCase().replace(regex, (m, $1) => {
    return data instanceof FormData ? data.get($1) : data[$1] || m
  })
}

let cacheMapper = {
  '/passwordpolicy': {
    minutes: 10,
    persist: true,
  },
  // '/configuration/theme': {
  //   minutes: 10,
  //   persist: true,
  // },
  // '/category': {
  //   minutes: 10,
  //   persist: true,
  // },
  '/location/detail/{id}': {
    minutes: 5,
    persist: true,
  },
  '/product/trending': {
    minutes: 10,
    persist: true,
  },
  '/footerdetails': {
    minutes: 10,
    persist: true,
  },
  '/category/trending': {
    minutes: 10,
    persist: true,
  },
  // 'search/frequent': { minutes: 5 },
  '/collection': {
    minutes: 10,
  },
  '/product/details': {
    minutes: 10,
  },
  '/product/companions': {
    minutes: 10,
  },
  '/product/substitutes': {
    minutes: 10,
  },
  '/discount': {
    minutes: 10,
  },
  // '/product': {
  //   minutes: 5,
  // },
  '/location/detail/{id}': {
    minutes: 10,
  },
  '/discount/{id}': {
    minutes: 10,
  },
  '/discount/{id}/triggerproduct': {
    minutes: 10,
  },
  '/footerdetails/paymentlogos': {
    minutes: 10,
    persist: true,
  },
  '/pagetemplate': {
    minutes: 5,
  },
  '/category/filters': {
    minutes: 5,
  },
}

const cachedGetter = async (reqPath, reqData, useIdentityInstance = false) => {
  let mapperUrl = reqPath

  if (!reqPath.startsWith('/')) reqPath = `/${reqPath}`

  if (reqPath?.indexOf('?') > -1) {
    mapperUrl = reqPath.slice(0, reqPath.indexOf('?'))
  }

  mapperUrl = normalizeUrlToPath(mapperUrl)

  let finalUrl = replaceUrl(reqPath, reqData),
    cacheConfig = cacheMapper[mapperUrl] || null,
    cacheString = finalUrl

  let finalReqData = { params: reqData?.params || {} }

  for (const key in finalReqData.params) {
    if (key && finalReqData.params[key])
      cacheString += `${key}=${finalReqData.params[key]}`
  }

  let cachedData

  if (cacheConfig) {
    // && !isDev
    if (cacheConfig.persist)
      cachedData = await persistedCacheStorage.getItem(cacheString)
    else cachedData = tempCacheStorage.getItem(cacheString)
  }

  if (cachedData) return new Promise((res, rej) => res(cachedData))

  let useAxiosInstance = apiInstance

  if (useIdentityInstance) useAxiosInstance = identityAxiosInstance

  return useAxiosInstance.get(reqPath, reqData).then((response) => {
    if (cacheConfig) {
      if (cacheConfig.persist) {
        persistedCacheStorage.setItem(
          cacheString,
          response,
          cacheConfig.minutes
        )
      } else
        tempCacheStorage.setItem(cacheString, response, cacheConfig.minutes)
    }

    return response
  })
}

// With Cache
export const axiosInstance = {
  ...apiInstance,
  get: cachedGetter,
}

export const identityInstance = {
  ...identityAxiosInstance,
  get: (reqPath, reqData) => cachedGetter(reqPath, reqData, true),
}

// Without Cache
// export const axiosInstance = apiInstance
// export const identityInstance = identityAxiosInstance
