import { convertArrayToObject } from 'src/utils'

export function toggleProductIsFavorite({ commit }, products = []) {
  let promise = this.$axiosInstance.post('/Favorite', {
    favoriteProducts: products,
  })

  promise.then((response) => {
    if (response.data.data) {
      commit(
        'MARK_AS_FAVORITES',
        convertArrayToObject(response.data.data.products, 'productID')
      )
    }
  })

  return promise
}

export async function getFavorites({ commit, rootGetters }, params) {
  if (
    !rootGetters['persisted/currentLocationId'] ||
    !rootGetters['auth/customerExists']
  )
    return false

  try {
    if (params.Page == 1) commit('SET_LOADING', true)
    let { data } = await this.$axiosInstance.get('/Favorite', {
      params: {
        locationID: rootGetters['persisted/currentLocationId'],
        fulfillmentCodeName:
          rootGetters['persisted/selectedFulfillmentCodeName'],
        zipCode: rootGetters['persisted/locationOrShippingZip'],
        ...params,
      },
    })

    commit('SET_TOTAL', data.data.count)

    let favorites = convertArrayToObject(data.data.items, 'productID')

    if (params.Page == 1) {
      commit('SET_FAVORITES', favorites)
      commit('SET_LOADING', false)
    } else {
      commit('ADD_FAVORITES', favorites)
    }

    return data.data.hasNext
  } catch (error) {}
}

export async function removeItemFromFavorites(
  { state, commit, dispatch },
  { productId, objKey }
) {
  let params = [
    {
      productID: productId,
      markAsFavorite: false,
    },
  ]

  dispatch('toggleProductIsFavorite', params)

  commit('REMOVE_ITEM_FROM_FAVORITES', objKey)
  commit('SET_TOTAL', state.totalCount - 1)
}

export async function getCategories({ commit, rootGetters }) {
  if (
    !rootGetters['persisted/currentLocationId'] ||
    !rootGetters['auth/isLoggedIn']
  ) 
    return false

  try {
    let params = {
      locationID: rootGetters['persisted/currentLocationId'],
    }
    commit('SET_LOADING_CATEGORY', true)
    this.$axiosInstance
      .get('/Favorite/Category', { params })
      .then((response) => {
        commit('SET_CATEGORIES', response.data.data)
      })
      .finally(() => {
        commit('SET_LOADING_CATEGORY', false)
      })
  } catch (error) {} 
}
