import { Platform } from 'quasar'
import { reloadPage } from 'src/utils'
import { identityInstance, removeAVSessionID } from 'boot/axios'
import { WebStorageStateStore } from 'oidc-client'
import CookieStorage from 'src/utils/CookieStorage'

export async function mapUser({ commit }) {
  try {
    let user = await this.$oidc.getUser()
    if (user) await commit('SET_USER', user)
    else commit('SET_USER', null)
    return user
  } catch (error) {
    commit('SET_USER', null)
  }

  return null
}

export async function signinSilent({ commit }) {
  try {
    return await this.$oidc.signinSilent()
  } catch (error) {}
}

export async function oidcGetUser({ commit }) {
  try {
    let user = await this.$oidc.getUser()
    return user
  } catch (error) {
    return null
  }
}

export async function getUserInfo({ commit }) {
  let userInfo = {}
  try {
    commit('SET_LOADING_USER', true)
    let { data } = await identityInstance.get('Account')
    if (data.succeeded && data.data) {
      commit('SET_USER_INFO', data.data)
      userInfo = data.data
    }
    return userInfo
  } catch (error) {
    return userInfo
  } finally {
    commit('SET_LOADING_USER', false)
  }
}

export async function signIn(
  { commit, rootGetters },
  { isSignup = false, signupAcr = '' } = {}
) {
  let signInExtraParam = {},
    businessConfigurations = rootGetters['common/businessConfigurations'],
    currentRoute = this.$router.currentRoute,
    routePath = ''

  // If tenant has lns enabled and login from checkout screen then redirect to cart page
  if (
    currentRoute.name == 'Checkout' &&
    [1, 2].includes(businessConfigurations.lnsSubscriptionType)
  )
    routePath = '/cart'
  else if (rootGetters['persisted/currentLocationId'])
    routePath = currentRoute.path

  if (routePath && !rootGetters['persisted/authRedirectUri'])
    await commit('persisted/SET_AUTH_REDIRECT_URI', routePath, {
      root: true,
    })

  let tenantAcr = businessConfigurations.tenantAcr
  let newAcrValues = `tenant:${tenantAcr}`

  // let path = ''
  // if (process.env.SERVER) {
  //   path = process.env.fullSsrUrl
  //   newAcrValues = `tenant:${tenantAcr} signUpUrl:${process.env.fullSsrUrl}/signup`
  // } else if (Platform && Platform.is && Platform.is.cordova) {
  //   path = `${location.protocol}//${location.hostname}${
  //     location.port ? ':' + location.port : ''
  //   }`
  //   newAcrValues = `tenant:${tenantAcr} signUpUrl:${process.env.ANDROID_DEEP_LINK}/signup`
  // } else {
  //   path = `${location.protocol}//${location.hostname}${
  //     location.port ? ':' + location.port : ''
  //   }`

  //   newAcrValues = `tenant:${tenantAcr} signUpUrl:${path}/signup`
  // }

  this.$oidc._settings._acr_values = newAcrValues

  if (isSignup) this.$oidc._settings._acr_values += signupAcr

  // if (socialPlatform)
  //   this.$oidc._settings._acr_values += ` idp:${socialPlatform} aat:signup`

  // if (Platform?.is?.cordova)
  // signInExtraParam = {
  //   extraTokenParams: {
  //     atl: 86400 * 365,
  //   },
  // }

  signInExtraParam = {
    extraQueryParams: {
      logourl: rootGetters['theme/theme'].logoURL || '',
      faviconurl: rootGetters['theme/theme'].faviconURL || '',
      backgroundurl: rootGetters['theme/theme'].backgroundURL || '',
      primarycolor: rootGetters['theme/theme'].primaryColor || '',
      backgroundcolor: rootGetters['theme/theme'].backgroundColor || '',
      secondarycolor: rootGetters['theme/theme'].secondaryColor || '',
      textcolor: rootGetters['theme/theme'].textColor || '',
      showpoweredby: true,
    },
  }

  // if (Platform.is.mobile || !process.env.LOGIN_ON_POPUP)
  //   this.$oidc.signinRedirect(signInExtraParam)
  // else
  // this.$oidc.signinPopup(signInExtraParam).then((user) => {
  //   reloadPage()
  //   // if (user) commit('SET_USER', user)
  // })

  if (!Platform.is?.cordova) this.$oidc.signinRedirect(signInExtraParam)
  else
    try {
      let user = await this.$oidc.signinPopup(signInExtraParam)
      // if (user) commit('SET_USER', user)
      reloadPage()
      // commit('common/SET_APP_LOADED', false, {
      //   root: true,
      // })

      return user
    } catch (error) {
      return null
    }
}

export function logout({ commit, rootGetters }) {
  // if (Platform.is.mobile || !process.env.LOGIN_ON_POPUP)
  // Clear EAIV Session on Logout
  const eaivPersistedSessionId = rootGetters['persisted/eaivPersistedSessionId']
  if (eaivPersistedSessionId) {
    removeAVSessionID()
    commit('persisted/SET_EAIV_SESSION_ID', null, { root: true })
    commit('persisted/SET_EAIV_VERIFICATION_STATUS', false, { root: true })
  }

  if (!Platform.is?.cordova)
    this.$oidc.signoutRedirect().then(() => {
      this.$oidc
        .clearStaleState(
          new WebStorageStateStore({
            store: new CookieStorage(),
          })
        )
        .then(() => {
          console.log('clearState success')
        })
        .catch((e) => {
          console.log('clearStateState error', e.message)
        })
    })
  else
    this.$oidc.signoutPopup().then(() => {
      reloadPage()
    })
}

export async function checkCustomerExits({ commit }) {
  try {
    let { data } = await this.$axiosInstance.get('/Signup/Exist')
    if (data?.success && data?.data?.alreadyExist) {
      commit('SET_CUSTOMER_EXIST', true)
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export async function signUpAsCustomer({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.post(`/Signup/Exist`, body)

    commit('SET_CUSTOMER_EXIST', true)
    return data
  } catch (error) {
    return error
  }
}

export async function deleteCustomer() {
  try {
    let { data } = await this.$axiosInstance.get('/Customer', {
      params: { global: true },
    })

    return data
  } catch (error) {
    return error
  }
}

export async function getPasswordPolicy({ commit }) {
  try {
    let { data } = await identityInstance.get('PasswordPolicy')
    if (data.succeeded && data.data) commit('SET_PASSWORD_POLICY', data.data)
  } catch (error) {}
}

export async function setFCMToken({}, body) {
  try {
    let { data } = await this.$axiosInstance.post('/Notification/Token', body)

    return data
  } catch (error) {
    return error
  }
}

export async function deleteFCMToken({}, deviceID) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `/Notification/Token/${deviceID}`
    )

    return data
  } catch (error) {
    return error
  }
}
