import { convertArrayToObject } from 'src/utils'
import { Platform } from 'quasar'
import { NotifyMethods } from 'src/boot/notify'

export async function getProductDetail({ commit }, params) {
  try {
    commit('SET_LOADING', true)

    let response = await this.$axiosInstance.get('/Product/Details', { params })
    let detail = { ...response.data.data }

    for (let variant of detail.variants) {
      if (variant.productVariantValueID == detail.productID) {
        detail.productVariantValues = variant.productVariantValues
        break
      }
    }

    commit('PUSH_PRODUCT_DETAIL', detail)

    commit('SET_LOADING', false)

    if (Platform?.is?.mobile && detail.title) {
      commit('common/SET_HEADER_TITLE', { title: detail.title }, { root: true })
    }

    return detail
  } catch (error) {}
}

export async function getSubstituteProducts({}, { params }) {
  try {
    let response = await this.$axiosInstance.get('/Product/Substitutes', {
      params,
    })
    return response.data.data
  } catch (error) {}
}

export async function getCompanionProducts({ commit }, params) {
  try {
    let response = await this.$axiosInstance.get('/Product/Companions', {
        params,
      }),
      companionProducts = convertArrayToObject(response.data.data, 'productID')

    commit('PUSH_COMPANION_PRODUCTS', {
      ProductSeoName: params.ProductSeoName,
      items: companionProducts,
    })
  } catch (error) {}
}

export async function getFrequentSearch({ commit, rootGetters }) {
  this.$axiosInstance
    .get(`/Search/Frequent`, {
      params: {
        Page: 1,
        PageSize: 5,
        IsAuto: true,
        global: true,
      },
    })
    .then((response) => {
      let searchKeys = Object.keys(
        convertArrayToObject(response.data.data.items, 'keyword')
      )
      commit('common/SET_FREQUENT_SEARCHES', searchKeys, { root: true })
    })
}

export async function getTrendingProducts({ commit, rootGetters }) {
  this.$axiosInstance
    .get('v1/Product/Trending', {
      params: {
        Page: 1,
        PageSize: 5,
        global: true,
        fulfillmentCodeName:
          rootGetters['persisted/selectedFulfillmentCodeName'],
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      },
    })
    .then((response) => {
      let trendingProducts = convertArrayToObject(
        response.data.data.items,
        'productID'
      )

      commit('common/SET_TRENDING_PRODUCTS', trendingProducts, { root: true })
    })
}

export async function getByKeyword({ rootGetters }, rParams) {
  try {
    let params = {
      ...rParams,
      locationID: rootGetters['persisted/currentLocationId'],
      zipCode: rootGetters['persisted/locationOrShippingZip'],
    }
    if (
      rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
      ((!rootGetters['auth/isLoggedIn'] &&
        !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
        (rootGetters['auth/isLoggedIn'] &&
          !rootGetters['customer/isVerifiedOnline']))
    ) {
      params['skipBrowsingAgeRestrictedProd'] = true
    }

    let { data } = await this.$axiosInstance.get('/Product/SearchKeyword', {
      params,
    })

    return data.data
  } catch (error) {
    return {}
  }
}

export async function getProducts({ rootGetters }, params) {
  try {
    let fParams = {
      ...params,
      global: true,
      zipCode: rootGetters['persisted/selectedZipCode'],
    }

    if (
      rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
      ((!rootGetters['auth/isLoggedIn'] &&
        !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
        (rootGetters['auth/isLoggedIn'] &&
          !rootGetters['customer/isVerifiedOnline']))
    ) {
      fParams['skipBrowsingAgeRestrictedProd'] = true
    }

    fParams['zipCode'] = rootGetters['persisted/locationOrShippingZip']
    let response = await this.$axiosInstance.get('/Product', {
      params: fParams,
    })

    return response.data.data
  } catch (error) {
    // if (this.$router.currentRoute.name !== 'Home') this.$router.push({ name: 'Home' }).catch(() => {})
    if (error.errors && error.errors[0])
      NotifyMethods.showError(
        `<strong>Error!</strong>${error.errors[0].descreption}.`
      )
  }
}

export async function getReviewsByProduct({}, params) {
  try {
    let response = await this.$axiosInstance.get('/CustomerReview/Product', {
      params,
    })

    return response.data.data
  } catch (error) {}
}

export async function getReviewsSummary({}, params) {
  try {
    let response = await this.$axiosInstance.get('/CustomerReview/Summary', {
      params,
    })

    return response.data.data
  } catch (error) {}
}

export async function getAvailableNearByStore({}, params) {
  try {
    let response = await this.$axiosInstance.get(
      '/Product/AvailableNearByStore',
      { params }
    )
    return response.data
  } catch (error) {}
}
export async function getProductAvailability({}, params) {
  try {
    let { data } = await this.$axiosInstance.get(
      '/Product/AvailableByZipcode',
      { params }
    )
    return data
  } catch (error) {
    return error
  }
}
